import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';


const logos = [
  {
    id: 1,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Client 1',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Client 2',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Client 3',
  },
  {
    id: 4,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Partner 1',
  },
  {
    id: 5,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Partner 2',
  },
  {
    id: 6,
    image: 'https://via.placeholder.com/150x50',
    alt: 'Partner 3',
  },
];

const LogoSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <div className="logo-slider">
      <Carousel
        showArrows={true}
        onChange={handleNext}
        onClickItem={handlePrev}
        selectedItem={currentIndex}
      >
        {logos.map((logo, index) => (
          <div key={index}>
            <img src={logo.image} alt={logo.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default LogoSlider;
import React from 'react'

const Who = () => {
  return (
    <div>
<div><img /></div>
<div></div>
    </div>
  )
}

export default Who

import React from 'react';
import image1 from '../Assets/img/Logo-Final.png';
import { Link } from "react-router-dom";
import { useState } from 'react';

const Header = () => {

  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();   

    // Perform search logic here, e.g., fetch data from an API or update the page content
    console.log('Search term:', searchTerm);
  };

  

  // JavaScript code
// const dropdown = document.querySelector('.dropdown');

// dropdown.addEventListener('mouseover', () => {
//   dropdown.querySelector('.dropdown-menu').classList.add('show');
// });

// dropdown.addEventListener('mouseleave', () => {
//   setTimeout(() => {
//     dropdown.querySelector('.dropdown-menu').classList.remove('show');
//   }, 100); // Adjust the delay as needed
// });


  return (
    <div>
    <header className='header fixed-top  '>
           
  
  <div class=" navbar container-fluid col-12 d-flex justify-content-end gap-4 px-5 " style={{ backgroundColor:'#dc3545' , height:'40px' }}>
    <Link to='#' class="bi bi-whatsapp  " style={{ fontSize: '24px' , color: 'rgb(248, 215, 216)'}}></Link>
    <Link to='#' class="bi bi-instagram" style={{ fontSize: '24px' , color: 'rgb(248, 215, 216)'}}></Link>
    <Link to='#' class="bi bi-twitter" style={{ fontSize: '24px' , color: 'rgb(248, 215, 216)'}}></Link>
    <Link to='#' class="bi bi-telegram" style={{ fontSize: '24px' , color: 'rgb(248, 215, 216)'}}></Link>
    <Link to='#' class="bi bi-facebook" style={{ fontSize: '24px' , color: 'rgb(248, 215, 216)'}}></Link>
  </div>
  

 
 
<div class="navbar navbar-expand-lg bg-body-tertiary ">
  <div class="container-fluid ">
  <div>
  <img src={image1} alt="My Image" height={90} width={120}/>
  </div>
    <div>
    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav me-auto  mb-2 mb-lg-0">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        
        <li class="nav-item dropdown">
          <Link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About
          </Link>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="who_we_are">Who We Are</Link></li>
            <li><Link class="dropdown-item" to="board_and_advisors">Board And Advisors</Link></li>
            <li><Link class="dropdown-item" to="ourteam">Our Team</Link></li>
            <li><Link class="dropdown-item" to="accountability">Accountability</Link></li>
            <li><Link class="dropdown-item" to="partnerships">Partnerships</Link></li>
            <li><Link class="dropdown-item" to="careers">Careers</Link></li>
            <li><Link class="dropdown-item" to="contact">Contact Us</Link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <p class="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            What We Do
          </p>
          <ul class="dropdown-menu">
          <li><Link class="dropdown-item" to="projects">Projects</Link></li>
            <li><Link class="dropdown-item" to="knowledge_partners">Knowledge Partners</Link></li>
            <li><Link class="dropdown-item" to="focus_areas">Focus Areas</Link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <p class="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Resorces
          </p>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="annual_reports">Annual Reports</Link></li>
            <li><Link class="dropdown-item" to="publications">Publications</Link></li>
            <li><Link class="dropdown-item" to="learning_material">Learning Material</Link></li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <Link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Media
          </Link>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="student_gallery">Student Gallery</Link></li>
            <li><Link class="dropdown-item" to="latest_news">Latest News</Link></li>
            <li><Link class="dropdown-item" to="field_voice">Field Voices</Link></li>
            <li><Link class="dropdown-item" to="testimonials">Testimonials</Link></li>
            <li><Link class="dropdown-item" to="newsletter">Newsletter</Link></li>
            <li><Link class="dropdown-item" to="press_coverage">Press Coverage</Link></li>
          </ul>
        </li>
      </ul>
      <div class='d-flex gap-3'>
      <form className="d-flex" role="search" onSubmit={handleSubmit}>
      <input
        className="form-control me-2"
        type="search"
        placeholder="Search"
        aria-label="Search"
        value={searchTerm}   

        onChange={(event) => setSearchTerm(event.target.value)}
      />
      <button className="btn   
 btn-outline-danger" type="submit">
        <i className="bi bi-search"></i>
      </button>
    </form>
      <button class="btn btn-outline-danger" type="submit">Donate</button>
      </div>
    </div>
    </div>
  </div>
</div>
</header>
    </div>
  )
}

export default Header

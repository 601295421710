
import './App.css';
import { BrowserRouter as Router , Routes, Route, Link } from 'react-router-dom';
import Home from './Pages/Home';
import Header from './Components/Header';
import About from './Pages/About';
import Accountability from './Pages/Accountability';
import Careers from './Pages/Careers';
import Learning from './Pages/Learning';
import Board from './Pages/Board';
// import {Janpehal,Who,What,Partnership,Press,Projects,Testimonials,StudentGal,OurTeam,Newsletter,LatestNews,Knowledge,FocusAreas,Field,Esp,Dilseop,Delhi,Contact,Connected,Annuals} from './Pages';
import Publications from './Pages/Publications';
import StudentGal from './Pages/StudentGal';
import Partnership from './Pages/Partnership';
import Knowledge from './Pages/Knowledge';
import OurTeam from './Pages/OurTeam';
import Janpehal from './Pages/Janpehal';
import What from './Pages/What';
import Who from './Pages/Who';
import Press from './Pages/Press';
import Newsletter from './Pages/Newsletter';
import LatestNews from './Pages/LatestNews';
import Esp from './Pages/Esp';
import Delhi from './Pages/Delhi';
import Dilseop from './Pages/Dilseop';
import Connected from './Pages/Connected';
import Contact from './Pages/Contact';
import Projects from './Pages/Projects';
import FocusAreas from './Pages/FocusAreas';
import Annual from './Pages/Annual';
import Field from './Pages/Field';



import Testimonials from './Components/Testimonials';
import Footer from './Components/Footer';

function App() {
  return (
    <div >
      <Router>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      
            <Routes>
                <Route path="/" element={<Home />} />
               
                <Route path="who_we_are" element={<Who />} />
                <Route path="board_and_advisors" element={<Board />} />
                <Route path="ourteam" element={<OurTeam />} />
                <Route path="accountability" element={<Accountability />} />
                <Route path="partnership" element={<Partnership />} />
                <Route path="careers" element={<Careers />} />
                <Route path="contact_us" element={<Contact />} />
                <Route path="projects" element={<Projects />} />
                <Route path="knowledge_partners" element={<Knowledge />} />
                <Route path="focus_areas" element={<FocusAreas />} />
                <Route path="annual_reports" element={<Annual />} />
                <Route path="publications" element={<Publications />} />
                <Route path="learning_material" element={<Learning />} />
                <Route path="student_gallery" element={<StudentGal />} />
                <Route path="latest_news" element={<LatestNews />} />
                <Route path="field_voice" element={<Field />} />
                <Route path="testimonials" element={<Testimonials />} />
                <Route path="newsletter" element={<Newsletter />} />
                <Route path="press_coverage" element={<Press />} />
                <Route path="education_signature_program" element={<Esp />} />
                <Route path="dil_se_open" element={<Dilseop />} />
                <Route path="jan_pahal" element={<Janpehal />} />
              
               
            </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import LogoSlider from './LogoSlider'
import './logo.css';
import image1 from '../Assets/img/01.png';
import image2 from '../Assets/img/02.png';
import image3 from '../Assets/img/03.png';
import image4 from '../Assets/img/04.png';
import image5 from '../Assets/img/05.png';
import image6 from '../Assets/img/06.png';
import image7 from '../Assets/img/07.png';
import image8 from '../Assets/img/08.png';
import image9 from '../Assets/img/09.png';
import image10 from '../Assets/img/10.png';
import image11 from '../Assets/img/11.png';
import image12 from '../Assets/img/12.png';
import image13 from '../Assets/img/13.png';
import image14 from '../Assets/img/14.png';
import image15 from '../Assets/img/cro2.jpg';
import image16 from '../Assets/img/environment.jpg';
import image17 from '../Assets/img/new.jpg';
import image18 from '../Assets/img/cro1.jpg';
import image19 from '../Assets/img/environment-cover.jpg';
import image20 from '../Assets/img/S8-scaled.jpg';



const Footer = () => {
  const images = [
    { src: image1, alt: 'Logo 1' },
    { src: image2, alt: 'Logo 2' },
    { src: image3, alt: 'Logo 3' },
    { src: image4, alt: 'Logo 4' },
    { src: image5, alt: 'Logo 5' },
    { src: image6, alt: 'Logo 6' },
    { src: image7, alt: 'Logo 7' },
    { src: image8, alt: 'Logo 8' },
    { src: image9, alt: 'Logo 9' },
    { src: image10, alt: 'Logo 10' },
    { src: image11, alt: 'Logo 11' },
    { src: image12, alt: 'Logo 12' },
    { src: image13, alt: 'Logo 13' },
    { src: image14, alt: 'Logo 14' },
  ];

  
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        if (prevSlide === images.length - 1) {
          return 0; // reset to 0 when reaching the end
        } else {
          return prevSlide + 1;
        }
      });
    }, 2000); // slide every 2 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);
  
  return (
    <div>
     <footer className="footer " style={{  backgroundColor : '#d1e7dd', color:''}}>
      <div>
      <div className='px-5' style={{ fontFamily:'Montserrat'}}><h2 className=' text-center px-5 ' style={{ color:'#000'}}>Our Partners And Donors</h2>
      </div>
      

        <div className=" d-grid place-items-center ">
      <div className="slider1">
        <div
          className="slider-track1"
          style={{
            transform: `translateX(${currentSlide * -25}%)`, // changed to -25%
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {images.map((image, index) => (
            <div key={index} className="slide1 align-content-center ">
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
          {images.slice(0, 4).map((image, index) => (
            <div key={index + images.length} className="slide1 align-content-center">
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>

    
{/* <div className='px-5' style={{ fontFamily:'Montserrat'}}><h3 className=' text-center px-5 display-7'>Join For Our Newsletter</h3></div>
      <div className=' d-flex justify-content-center'>
      <form >
  <div class="mb-3 " style={{ width:"800px"}}>
    
    <input type="text" class="form-control" style={{ fontFamily:'Montserrat'}} id="exampleInputName1" aria-describedby="nameHelp" placeholder='Name' />
   
  </div>
  <div class="mb-3" style={{ width:"800px"}}>

    <input type="email" class="form-control" style={{ fontFamily:'Montserrat'}} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email'/>
  </div>
 
  <button type="submit" class="btn btn-primary" style={{ width:"800px"}}>Subscribe</button>
</form>
      </div> */}

      <div class="container-fluid footer text-black py-5">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item">
                            <h4 class="mb-4 text-black">Newsletter</h4>
                            <p class="mb-4 ">NRBC Ranai Pahad: A Collective Step Towards Education</p>
                          
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item d-flex flex-column ">
                            <h4 class="mb-4 text-black">Our Services</h4>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Ocean Turtle</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> White Tiger</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Social Ecology</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Loneliness</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Beauty of Life</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Present for You</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item d-flex flex-column">
                            <h4 class="mb-4 text-black">Volunteer</h4>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Karen Dawson</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Jack Simmons</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Michael Linden</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Simon Green</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Natalie Channing</a>
                            <a href=""><i class="bi bi-chevron-right me-2"></i> Caroline Gerwig</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="footer-item">
                            <h4 class="mb-4 text-black">Our Gallery</h4>
                            <div class="row g-2">
                                <div class="col-6">
                                    <div class="footer-gallery">
                                        <img src={image20} class="img-fluid w-100" alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image20} data-lightbox="footerGallery-1" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
                                    </div>
                               </div>
                               <div class="col-6">
                                    <div class="footer-gallery">
                                        <img src={image19} class="img-fluid w-100" alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image19} data-lightbox="footerGallery-2" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
                                    </div>
                               </div>
                                {/* <div class="col-4">
                                    <div class="footer-gallery">
                                        <img src={image16} class="img-fluid w-100" alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image16} data-lightbox="footerGallery-3" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
                                    </div>
                               </div> */}
                                {/* <div class="col-4">
                                    <div class="footer-gallery">
                                        <img src={image17} class="img-fluid w-100 h-100"  alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image17} data-lightbox="footerGallery-4" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
                                    </div>
                               </div> */}
                                <div class="col-6">
                                    <div class="footer-gallery">
                                        <img src={image15} class="img-fluid w-100" alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image15} data-lightbox="footerGallery-5" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
                                    </div>
                               </div>
                               <div class="col-6">
									<div class="footer-gallery">
										<img src={image18} class="img-fluid w-100" alt="" />
                                        <div class="footer-search-icon">
                                            <a href={image18} data-lightbox="footerGallery-6" class="my-auto"><i class="bi bi-search text-black"></i></a>
                                        </div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-relative mx-auto ">
                            <h2 className='text-black'>Join Our Newsletter</h2>
                            <div className='d-flex justify-content-center'>
                            <div class="mb-3 " style={{ width:"800px"}}>

<input type="email" class="form-control" style={{ fontFamily:'Montserrat'}} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email'/>
</div>
</div>
                                <button type="button" class=" btn-hover-bg btn btn-danger">Subscribe</button>
                            </div>

      {/* <div className='text-black'>
      <div className='fw-bold'><br />
      Aspire - A Society for Promotion of Inclusive and Relevant Education<br />

      <br />
      </div>
      <div className='fw-bold'>
        
      1st & 2nd Floors<br />
      B117, Sarvodaya Enclave<br />
      New Delhi - 110017<br/>
      <br />
      +91 01146516788<br />
       contactus@aspire-india.org<br/>
       
      
      </div>
      </div> */}
      <div className='d-flex gap-5 justify-content-center p-2'>
       <a href='#' class="bi bi-whatsapp" style={{ fontSize: '24px' , color: 'rgba(187,33,36,.8)'}}></a>
    <a href='#' class="bi bi-instagram" style={{ fontSize: '24px' , color: 'rgba(187,33,36,.8)'}}></a>
    <a href='#' class="bi bi-twitter" style={{ fontSize: '24px' , color: 'rgba(187,33,36,.8)'}}></a>
    <a href='#' class="bi bi-telegram" style={{ fontSize: '24px' , color: 'rgba(187,33,36,.8)'}}></a>
    <a href='#' class="bi bi-facebook" style={{ fontSize: '24px' , color: 'rgba(187,33,36,.8)'}}></a>
      </div>
        <p className="text-center text-black">&copy; {new Date().getFullYear()} Aspire</p>
      </div>
     </footer>
    </div>
 )
}

export default Footer

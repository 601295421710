import React , { useState, useEffect } from 'react';
import image1 from '../Assets/img/test1.jpg';
import image2 from '../Assets/img/test2.png';
import image3 from '../Assets/img/test3.jpg';
import image4 from '../Assets/img/test4.jpg';
import image5 from '../Assets/img/test5.jpg';
import image6 from '../Assets/img/test6.jpeg';
import image7 from '../Assets/img/test7.jpg';
import image8 from '../Assets/img/test8.jpeg';
import image9 from '../Assets/img/test9.jpg';
import image10 from '../Assets/img/test10.jpeg';



const Testimonials = () => {



      



  return (
    <>
    <div className='mx-5 text-danger text-center'><h1>Testimonials</h1> </div>
    <div className='d-flex justify-content-around mt-4'>
    
      <div id="carouselExampleControls" class="carousel slide " data-bs-ride="carousel" data-bs-interval="2000" >
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div class="card" style={{width: '18rem'}}>
  <img src={image1} class="card-img-top" alt="..." />
  <div class="card-body">
    <h5 class="card-title">G. N. Devy</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
</div>

    </div>
    <div class="carousel-item ">
    <div class="card" style={{width: '18rem'}}>
  <img src={image6} class="card-img-top" alt="..."  />
  <div class="card-body">
    <h5 class="card-title">Prof.(Dr.) N. V. Varghese</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    
  </div>
</div>

    </div>
    <div class="carousel-item ">
    <div class="card" style={{width: '18rem'}}>
  <img src={image3} class="card-img-top" alt="..."  />
  <div class="card-body">
    <h5 class="card-title">Chandrani Murmu</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
   
  </div>
</div>

    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>
    </>
  )
}

export default Testimonials

import React from 'react';
import '../Assets/css/style.css'
import image1 from '../Assets/img/cro1.jpg'
import image2 from '../Assets/img/cro2.jpg'
import image3 from '../Assets/img/cro3.jpg'
import image4 from '../Assets/img/Our-Strategy.jpg';
import image5 from '../Assets/img/Education.png'
import image6 from '../Assets/img/Growth.png'
import image7 from '../Assets/img/Community1.png'
import image8 from '../Assets/img/Teacher.png'
import image9 from '../Assets/img/Cover-7.jpg'
import image10 from '../Assets/img/environment.jpg'
import image11 from '../Assets/img/new.jpg'
import image12 from '../Assets/img/environment-cover.jpg'
import Testimonials from '../Components/Testimonials';


import { useState } from 'react';



const Home = () => {

  const [activeButton, setActiveButton] = useState('Access'); // Set a default active button

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (item) => {
    setActiveItem(item);
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);   

  };

  return (
    <div>

    {/* Carousel Start  */}
    <div id="carouselExampleAutoplaying" class="carousel slide " data-bs-ride="carousel" >
  <div class="carousel-inner">
    <div class="carousel-item active " data-bs-interval="2000">
      <img src={image1} class="d-block w-100" alt="..."  />
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))'
      }} />
      {/* <div class="carousel-caption d-none d-md-block">
      <button type="button" class=" btn btn-outline-danger " style={{ width: '15%'}}><i class="bi bi-camera-reels " style={{ fontSize: '24px' , color: 'whitesmoke'}}></i></button>
        <p>Some representative placeholder content for the first slide.</p>
      </div> */}
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src={image2} class="d-block w-100" alt="..." />
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))'
      }} />
      {/* <div class="carousel-caption d-none d-md-block">
      <button type="button" class=" btn btn-outline-danger " style={{ width: '15%'}}><i class="bi bi-camera-reels " style={{ fontSize: '24px' , color: 'whitesmoke'}}></i></button>
        <p>Some representative placeholder content for the first slide.</p>
      </div> */}
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src={image3} class="d-block w-100" alt="..." />
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))'
      }} />
      {/* <div class="carousel-caption d-none d-md-block">
      <button type="button" class=" btn btn-outline-danger " style={{ width: '15%'}}><i class="bi bi-camera-reels " style={{ fontSize: '24px' , color: 'whitesmoke'}}></i></button>
        <p>Some representative placeholder content for the first slide.</p>
      </div> */}
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

{/* About Start */}
<div>
<div className=' m-5 d-flex'>
    <div>
        <img src={image4} alt='' style={{ borderRadius: '10px' , marginLeft:'20px' }}/>
    </div>
    <div className='mx-5'>

        <h4 style={{ color:'#dc3545' ,textTransform:'uppercase' , marginTop:'5px', fontWeight:'bold' , fontFamily:'Montserrat' }}>About Us</h4>
        <h2 style={{ fontFamily:'Montserrat'}}>Our Main Goal Is To Provide Education To Remotest Areas </h2>
        <p style={{ fontSize:'large',  fontFamily:'Montserrat' }}>Aspire is a registered non profit organisation working towards making education inclusive, socially relevant, and keeping pace with the 21st century challenges. We are a people’s movement to ensure all children have the best possible learning opportunities and complete secondary schooling through a revitalised government school system.</p>
        <p style={{ fontSize:'large',  fontFamily:'Montserrat' }}>We work with communities from the remotest areas of India to ensure quality school education for all children and help them realise their full potential.</p>
 

        <div className='	bg-danger-subtle h-50 '> 
             <h2 className='text-center pt-3 pb-2' style={{ fontFamily:'Montserrat'}}>Three Pillars</h2>
              <div className='d-flex gap-5 justify-content-around pb-1'>
              
              <button type="button" class="btn btn-outline-danger " onClick={() => handleButtonClick('Access')}>Access</button>
              <button type="button" class="btn btn-outline-danger" onClick={() => handleButtonClick('Learning')}>Learning</button>
              <button type="button" class="btn btn-outline-danger" onClick={() => handleButtonClick('Governance')}>Governance</button>
              </div>
              {activeButton === 'Access' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>We ensure every child joins Anganwadis (early childhood centres), transitions to school, and completes grade 10. All out of school children are identified and brought back to school through direct enrolment and bridge courses.</div>}
               {activeButton === 'Learning' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%' }}>We address foundational learning deficits and enable children to become self directed learners. Our innovative pedagogical practices cater to 21st century learning needs of children.</div>}
              {activeButton === 'Governance' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>We ensure effective school management and governance by empowering communities and local self-government bodies, like School Management Committees, Parent Teachers Associations, and Gram Panchayats to take ownership of education in their area.</div>}
        </div>
        
    </div>
</div>
</div>

{/* what we do  */}
<div class="container-fluid service py-5 bg-light">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5" style={{maxWidth: '800px'}}>
                    <h5 class="text-uppercase ">What we do</h5>
                    <h1 class="mb-0 text-danger">What We Do To Ensure Quality Education</h1>
                </div>
                <div class="row g-4">
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image7} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Communities have the desire</p>
                            </div>
                        </div>
                        <p class="my-4 h5">Communities have the desire and potential to usher change. We facilitate this process.
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image8} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Right To Education</p>
                            </div>
                        </div>
                        <p class="my-4 h5">Education is a public good and the primary responsibility of the state. We work to strengthen the public education system.
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image5} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Teacher Empowerment.</p>
                            </div>
                        </div>
                        <p class="my-4 h5">Disempowered teachers cannot create empowered children. We take every effort towards teacher empowerment.
</p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image6} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Effective School Management Committee (SMC)</p>
                            </div>
                        </div>
                        <p class="my-4 h5">Every child has unlimited potential for growth. We create an environment for children to unleash their true potential.
                        </p>
                    </div>
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <a class=" btn btn-danger text-white py-2 px-4" href="#">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

{/* Our Core Beliefs*/}
 {/* <div>
  <div className='px-5' style={{ fontFamily:'Montserrat' ,textTransform:'uppercase'}}><h1 className='text-end px-5 display-5' style={{ color:'#dc3545' , textDecoration:'underline'}}>Our Core Beliefs</h1></div>
  <div className='d-flex justify-content-around'>
  <div className='m-5'>
  <div className='bg-primary-subtle p-3 rounded' ><img src={image7} width={250}/></div>
  <div className='p-3 ' style={{ fontFamily:'Montserrat' , fontSize:'x-large' }}> Communities have the desire and potential to usher change. We facilitate this process.</div>
  </div>

  <div className='m-5'>
  <div className='bg-danger-subtle p-3 rounded'><img src={image5} width={250}/></div>
  <div className='p-3 ' style={{ fontFamily:'Montserrat' , fontSize:'x-large' }}>Education is a public good and the primary responsibility of the state. We work to strengthen the public education system.</div>
  </div>

  <div className='m-5'>
  <div className='bg-warning-subtle p-3 rounded'><img src={image6} width={250}/></div>
  <div className='p-3 ' style={{ fontFamily:'Montserrat' , fontSize:'x-large' }}>Disempowered teachers cannot create empowered children. We take every effort towards teacher empowerment.</div>
  </div>

  <div className='m-5'>
  <div className='bg-info-subtle p-3 rounded'><img src={image8} width={250}/></div>
  <div className='p-3 ' style={{ fontFamily:'Montserrat' , fontSize:'x-large' }}>Every child has unlimited potential for growth. We create an environment for children to unleash their true potential.</div>
  </div>
  
  </div>
</div> */}

{/* Our Strategy  */}
<div className='d-flex justify-content-around mt-3'>
 
  <div className=' col-6 px-5' style={{ fontFamily:'Montserrat' }}><h1 className=' px-5 display-5 ' style={{ color:'#dc3545', textTransform:'uppercase'}}>Our Strategy</h1>
  <p className='p-4 ' style={{ fontFamily:'Montserrat' , fontSize:'x-large' }}>Working at scale with momentum creates a critical mass to change social norms – from accepting child labour to ensuring every child is in school.</p>
  <ul >
        <li onMouseEnter={() => handleMouseEnter('AREA SATURATION')} onMouseLeave={handleMouseLeave} style={{ color:'' , fontSize:'x-large'}}>AREA SATURATION</li>
        {hoveredItem === 'AREA SATURATION' && <div className='' style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Cover an entire administrative unit (e.g. block, district) to engage all actors in the system.</div>}
        <hr />
        <li onMouseEnter={() => handleMouseEnter('EDUCATION AS A FUNDAMENTAL RIGHT')} onMouseLeave={handleMouseLeave} style={{ color:'' , fontSize:'x-large'}}>EDUCATION AS A FUNDAMENTAL RIGHT</li>
        {hoveredItem === 'EDUCATION AS A FUNDAMENTAL RIGHT' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Cover all children in the program area because education is a fundamental right of every child.</div>}
        <hr />
        <li onMouseEnter={() => handleMouseEnter('EDUCATION AS A UNIFYING FORCE')} onMouseLeave={handleMouseLeave} style={{ color:'' , fontSize:'x-large'}}>EDUCATION AS A UNIFYING FORCE</li>
        {hoveredItem === 'EDUCATION AS A UNIFYING FORCE' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Making ‘schooling for all’ the agenda for the entire society.</div>}
        <hr />
        <li onMouseEnter={() => handleMouseEnter('THREE PILLAR APPROACH')} onMouseLeave={handleMouseLeave} style={{ color:'' , fontSize:'x-large'}}>THREE PILLAR APPROACH</li>
        {hoveredItem === 'THREE PILLAR APPROACH' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Address 3 key aspects of education – Access, Learning and Governance – simultaneously and at scale.</div>}
        <hr />
        <li onMouseEnter={() => handleMouseEnter('HIRE LOCALLY' )} onMouseLeave={handleMouseLeave} style={{ color:'' , fontSize:'x-large'}}>HIRE LOCALLY</li>
        {hoveredItem === 'HIRE LOCALLY' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Hire locally to ensure capacity building at local level and make certain that communities take ownership of the program.</div>}
        <hr />
        <li onMouseEnter={() => handleMouseEnter('DECENTRALISED GOVERNANCE')} onMouseLeave={handleMouseLeave} style={{ color:'#dc3545' , fontSize:'x-large'}}>DECENTRALISED GOVERNANCE</li>
        {hoveredItem === 'DECENTRALISED GOVERNANCE' && <div style={{ fontSize:'large',fontFamily:'Montserrat' , padding:'3%'}}>Community and local self-government bodies are at the forefront of program implementation.</div>}
       
      </ul>
     
  </div>
  <div className=' hello1 col-6'>
  <img src={image4} alt='' className='m-3' style={{ borderRadius: '10px' }}/>
  </div>
  
</div>

{/* Donation Start  */}
<div class="container-fluid donation bg-light py-5">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5" style={{maxWidth: '800px'}}>
                    <h5 class="text-uppercase ">Donation</h5>
                    <h1 class="mb-0 text-danger ">Your Money Will Helps In Childrens Growth</h1>
                </div>
                <div class="row g-4">
                    <div class="col-lg-4">
                        <div class="donation-item">
                            <img src={image11} class="img-fluid w-100" alt="Image" />
                            <div class="donation-content d-flex flex-column">
                                <h5 class="text-uppercase text-danger mb-4 ">Child Welfare</h5>
                                <a href="#" class="btn-hover-bg display-6 text-white">Help Us More</a>
                                <h4 class="text-white mb-4">Child Growth</h4>
                                <p class="text-white mb-4">Nurturing young minds, shaping a brighter future. We believe that every child deserves the chance to grow, learn, and thrive. Our mission is to provide access to quality education, healthcare, and nutrition, empowering children to reach their full potential. Together, let's give children the foundation they need to succeed and make a lasting impact on the world.</p>
                                <div class="donation-btn d-flex align-items-center justify-content-start">
                                    <a class=" btn btn-danger text-white py-2 px-4" href="#">Donate !</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="donation-item">
                            <img src={image9} class="img-fluid w-100" alt="Image" />
                            <div class="donation-content d-flex flex-column">
                                <h5 class="text-uppercase text-danger mb-4">Education System</h5>
                                <a href="#" class="btn-hover-bg display-6 text-white">Help Us More</a>
                                <h4 class="text-white mb-4">World Growth </h4>
                                <p class="text-white mb-4">Education is the spark that ignites growth. We believe that every child deserves access to quality education, empowering them to become change-makers and leaders. Our mission is to bridge the education gap, fostering a generation of innovators, thinkers, and problem-solvers. Together, let's educate for a brighter, more prosperous world.</p>
                                <div class="donation-btn d-flex align-items-center justify-content-start">
                                    <a class=" btn btn-danger text-white py-2 px-4" href="#">Donate !</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="donation-item">
                            <img src={image10} class="img-fluid w-100" alt="Image" />
                            <div class="donation-content d-flex flex-column">
                                <h5 class="text-uppercase text-danger mb-4">Organic</h5>
                                <a href="#" class="btn-hover-bg display-6 text-white">Help Us More</a>
                                <h4 class="text-white mb-4">Health And Nutrition</h4>
                                <p class="text-white mb-4">Empowering communities to thrive through healthy living. We believe that everyone deserves access to nutritious food and quality healthcare. Our mission is to provideeducation, resources, and support to create a healthier world for all. Join us in our journey to nourish bodies and minds.</p>
                                <div class="donation-btn d-flex align-items-center justify-content-start">
                                    <a class=" btn btn-danger text-white py-2 px-4" href="#">Donate !</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <a class=" btn btn-danger text-white py-2 px-4" href="#">All Donation</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     

{/* Our Impact  */}
<div>
<div className='px-5 mt-3 ' style={{ fontFamily:'Montserrat' , textTransform:'uppercase'}}><h1 className='text-end px-5 display-5' style={{ color:'#dc3545' }}>Our Impact</h1></div>
<div className='d-flex gap-3 mt-1 mx-5 justify-content-evenly' style={{ fontFamily:'Montserrat'}}>
  <div className=' hello3 col-4 bg-danger-subtle text-center m-5 pt-5' style={{ borderRadius:'50%', width:'300px', height:'300px'}}><h1 className='pt-5' style={{ color:'#dc3545' }}>12,00,463</h1>
  <h2>Children reached</h2></div>
  <div className='hello3 col-4 bg-warning-subtle text-center m-5 pt-5' style={{ borderRadius:'50%', width:'300px', height:'300px'}}><h1 className='pt-5 text-warning' >9,63,529+</h1>
  <h2>HouseHolds reached</h2></div>
  <div className='hello3 col-4 bg-danger-subtle text-center m-5 pt-5' style={{ borderRadius:'50%', width:'300px', height:'300px'}}><h1 className='pt-5' style={{ color:'#dc3545' }} >12,00,463</h1>
  <h2>Government School Supported</h2></div>
</div>
</div>



        {/* our gallery  */}
        <div class="container-fluid gallery bg-light py-5 px-0">
            <div class="text-center mx-auto pb-5" style={{maxWidth: '800px'}}>
                <h5 class="text-uppercase ">Our work</h5>
                <h1 class="mb-4 text-danger">We consider Child welfare</h1>
                <p class="mb-0"></p>
            </div>
            <div class="row g-0">
                <div class="col-lg-4">
                    <div class="gallery-item">
                        <img src={image3} class="img-fluid w-100" alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                    <div class="gallery-item">
                        <img src={image9} class="img-fluid w-100 " alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="gallery-item">
                        <img src={image10} class="img-fluid w-100" alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                    <div class="gallery-item">
                        <img src={image1} class="img-fluid w-100" alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                <div class="gallery-item">
                        <img src={image12} class="img-fluid w-100" alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                    <div class="gallery-item">
                        <img src={image11} class="img-fluid w-100" alt="" />
                        
                        <div class="gallery-content">
                            <div class="gallery-inner pb-5">
                                <p class="h4 text-white">Beauty Of Life</p>
                                <p class="text-white"><p class="mb-0">Gallery Name</p></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>


{/* Our Focus Areas  */}
{/* <div class="container-fluid service py-5 ">
<div className='px-5' style={{ fontFamily:'Montserrat' , textTransform:'uppercase'}}><h1 className=' px-5 display-5' style={{ color:'#dc3545' }}>Our Focus Areas</h1></div>

            <div class="container py-5">
                <div class="row g-4">
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image1} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Education</p>
                            </div>
                        </div>
                       
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image8} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Right To Education</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image5} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Teacher Empowerment.</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="service-item">
                            <img src={image6} class="img-fluid w-100" alt="Image" />
                            <div class="service-link">
                                <p class="h4 mb-0 text-white">Effective School Management Committee (SMC)</p>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div> */}

{/* Recent Causes  */}
<div class="container-fluid causes py-5">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5" style={{maxWidth: '800px'}}>
                    <h5 class="text-uppercase ">Recent Causes</h5>
                    <h1 class="mb-4 text-danger">The environment needs our protection</h1>
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                    </p>
                </div>
                <div class="row g-4">
                    <div class="col-lg-6 col-xl-3">
                        <div class="causes-item">
                            <div class="causes-img">
                                <img src={image5} class="img-fluid w-100" alt="Image" />
                                <div class="causes-link pb-2 px-3">
                                    <small class="text-white"><i class="fas fa-chart-bar text-primary me-2"></i>Goal: $3600</small>
                                    <small class="text-white"><i class="fa fa-thumbs-up text-primary me-2"></i>Raised: $4000</small>
                                </div>
                                <div class="causes-dination p-2">
                                    <a class=" btn btn-danger text-white py-2 px-3" href="#">Donate Now</a>
                                </div>
                            </div>
                          
                            <div class="causes-content bg-danger-subtle p-4">
                                <h4 class="mb-3">First environments activity of</h4>
                                <p class="mb-4">Help today because tomorrow you may be the one who needs more helping!</p>
                                <a class=" btn btn-danger text-white py-2 px-3" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-3">
                        <div class="causes-item">
                            <div class="causes-img">
                                <img src={image6} class="img-fluid w-100" alt="Image" />
                                <div class="causes-link pb-2 px-3">
                                    <small class="text-white"><i class="fas fa-chart-bar text-primary me-2"></i>Goal: $3600</small>
                                    <small class="text-white"><i class="fa fa-thumbs-up text-primary me-2"></i>Raised: $4000</small>
                                </div>
                                <div class="causes-dination p-2">
                                    <a class=" btn btn-danger text-white py-2 px-3" href="#">Donate Now</a>
                                </div>
                            </div>
                            
                            <div class="causes-content bg-danger-subtle  p-4">
                                <h4 class="mb-3">Build school for poor children.</h4>
                                <p class="mb-4">Help today because tomorrow you may be the one who needs more helping!</p>
                                <a class=" btn btn-danger text-white py-2 px-4" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-3">
                        <div class="causes-item">
                            <div class="causes-img">
                                <img src={image7} class="img-fluid w-100" alt="Image" />
                                <div class="causes-link pb-2 px-3">
                                    <small class="text-white"><i class="fas fa-chart-bar text-primary me-2"></i>Goal: $3600</small>
                                    <small class="text-white"><i class="fa fa-thumbs-up text-primary me-2"></i>Raised: $4000</small>
                                </div>
                                <div class="causes-dination p-2">
                                    <a class=" btn btn-danger text-white py-2 px-3" href="#">Donate Now</a>
                                </div>
                            </div>
                            <div class="causes-content bg-danger-subtle  p-4">
                                <h4 class="mb-3">Building clean-water system for rural poor.</h4>
                                <p class="mb-4">Help today because tomorrow you may be the one who needs more helping!</p>
                                <a class=" btn btn-danger text-white py-2 px-4" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-3">
                        <div class="causes-item">
                            <div class="causes-img">
                                <img src={image8} class="img-fluid w-100" alt="Image" />
                                <div class="causes-link pb-2 px-3">
                                    <small class="text-white"><i class="fas fa-chart-bar text-primary me-2"></i>Goal: $3600</small>
                                    <small class="text-white"><i class="fa fa-thumbs-up text-primary me-2"></i>Raised: $4000</small>
                                </div>
                                <div class="causes-dination p-2">
                                    <a class=" btn btn-danger text-white py-2 px-3" href="#">Donate Now</a>
                                </div>
                            </div>
                            
                            <div class="causes-content bg-danger-subtle  p-4">
                                <h4 class="mb-3">First environments activity of this summer.</h4>
                                <p class="mb-4">Help today because tomorrow you may be the one who needs more helping!</p>
                                <a class=" btn btn-danger text-white py-2 px-4" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* Testimonials */}
        <Testimonials />
        




    </div>
  )
}

export default Home

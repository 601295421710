import React from 'react'

const FocusAreas = () => {
  return (
    <div>
      
    </div>
  )
}

export default FocusAreas
